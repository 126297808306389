<template>
  <div>
    <div class="vx-row">
      <feather-icon
        v-if="EnglishLan"
        @click="$router.go(-1)"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="$router.go(-1)"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
    </div>
    <img style="width: 100%" alt="" src="@/assets/images/patientInfo.png" class="mt-4" />
    <div class="ml-10 mr-10">
      <div class="vx-row w-full con-img justify-center">
        <img
          v-if="hospital.ImagePath && hospital.ImagePath != ''"
          :src="baseURL + hospital.ImagePath"
          alt="user-img"
          width="200"
          height="150"
          class="rounded-full shadow-md cursor-pointer block m-4"
        />

        <img
          v-else
          :src="baseURL + 'Uploads/docotrGeneral.jpg'"
          alt="user-img"
          width="200"
          height="150"
          class="rounded-full shadow-md cursor-pointer block m-4"
        />
        <div class="position: absolute py-12 w-1">
          <input
            type="file"
            class="hidden"
            ref="uploadProfile"
            @change="updateCurrImg"
            accept="image/*"
            @on-success="successUpload"
          />

          <vs-avatar
            @click="$refs.uploadProfile.click()"
            color="primary"
            icon-pack="feather"
            icon="icon-upload"
            class="m-12"
          />
        </div>
      </div>

      <h2>
        {{ $t("Pleasecompleteyourregistrationtoaccessourservices") }}
      </h2>

      <div class="w-full justify-center">
        <div class="vx-row w-full justify-center">
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="Hospitalname"
              :label="$t('Hospitalname')+'*'"
              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('Hospitalname')"
              v-model="hospital.NameEN"
              :icon-after="true"
            />
          </div>
          <div div class="w-full justify-center">
            <label class="vs-input--label"> {{$t('HospitalGroup')}}</label>
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="Name"
              label="Name"
              class="w-full mt-2"
              :placeholder="$t('HospitalGroup')"
              :icon-after="true"
              :options="hospitalGroups"
              v-model="hospital.HospitalGroupID"
              :reduce="(ID) => ID.ID"
            />
          </div>
          <div div class="w-full justify-center">
            <label class="vs-input--label"> {{$t('HospitalInfoEN')+'*'}}</label>
             <quill-editor v-model="hospital.HospitalInfoEN" :options="editorOption" />
            
          </div>
          <div div class="w-full justify-center">
            <label class="vs-input--label"> {{$t('HospitalInfoAR')+'*'}}</label>
             <quill-editor v-model="hospital.HospitalInfoAR" :options="editorOption" />
            
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="HospitalRooms"
              :label="$t('HospitalRooms')"
              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('HospitalRoomsEN')"
              v-model="hospital.RoomDescriptionEN"
              :icon-after="true"
            />
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="HospitalRooms"
              :label="$t('HospitalRooms')"
              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('HospitalRoomsAR')"
              v-model="hospital.RoomDescriptionAR"
              :icon-after="true"
            />
          </div>
          <!-- <vs-checkbox  class="mt-1 w-full justify-left" v-model="hospital.IsAllowBankTransfare">{{
              $t(
                "IsAllowBankTransfare"
              )
            }}
          </vs-checkbox>
           <div v-if="hospital.IsAllowBankTransfare" class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="AccountNumber"

              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('AccountNumber')"
              v-model="hospital.AccountNumber"
              ::icon-after="true"
            />
          </div>
           <div v-if="hospital.IsAllowBankTransfare" class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="BankName"

              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('BankName')"
              v-model="hospital.BankName"
              ::icon-after="true"
            />
          </div>
           <div v-if="hospital.IsAllowBankTransfare" class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="BranchAddress"
              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('BranchAddress')"
              v-model="hospital.BranchAddress"
              ::icon-after="true"
            />
          </div>
           <div v-if="hospital.IsAllowBankTransfare" class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="IBAN"

              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('IBAN')"
              v-model="hospital.IBAN"
              ::icon-after="true"
            />
          </div> -->
          <div class="w-full justify-left mt-2">
            <h4>
              {{ $t("AdditionalInformation") }}
            </h4>
          </div>
          <div class="justfiy-center w-full mt-3">
            <div>
              <p class="text-align-left">{{ $t("HospitalImages") }}</p>
            </div>
            <div class="vx-row w-full ml-5 mr-5">
              <div
                v-for="item in hospital.HospitalAttachments"
                :key="item.ID"
                class="lg:w-1/4 w-full"
              >
                <div>
                  <div style="position: absolute">
                    <feather-icon
                      icon="XIcon"
                      style="color: red"
                      svgClasses="w-10 h-10 hover:text-primary stroke-current"
                      @click.stop="removeImage(item)"
                    />
                  </div>
                  <div>
                    <img
                      style="border-radius: 10px; opacity: 1"
                      class="p-1"
                      :src="baseURL + item.AttachPath"
                      width="200"
                      height="200"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <input
                type="file"
                ref="uploadImgInput"
                @change="AttachmentUploaded"
                accept="image/*"
                @on-success="successUpload"
              />
            </div>
          </div>
          <div div class="w-full justify-center">
            <VuePhoneNumberInput :placeholder="$t('PhoneNumber')" :default-country-code="defaultPhoneData.countryCode" @update="acceptNumber" v-model="hospital.Phone" />
            <!-- <VuePhoneNumberInput :default-country-code="defaultPhoneData.countryCode" @update="acceptNumber" v-model="hospital.Phone" /> -->
            <!-- <vs-input
              data-vv-validate-on="blur"
              name="PhoneNumber"
              class="w-full mt-3"
              :label="$t('PhoneNumber')"
              icon-no-border
              type="text"
               @input="acceptNumber"
              :placeholder="$t('PhoneNumber')"
              v-model="hospital.Phone"
              :icon-after="true"
            /> -->
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="ContactPerson"
              :label="$t('ContactPerson')"
              class="w-full mt-3"
              icon-no-border
              :placeholder="$t('ContactPerson')"
              v-model="hospital.ContactPersonName"
              :icon-after="true"
            />
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="ContactPersonEmail"
              class="w-full mt-3"
                :label="$t('ContactPersonEmail')"
              icon-no-border
              :placeholder="$t('ContactPersonEmail')+'*'"
              v-model="hospital.ContactPersonEmail"
              :icon-after="true"
            />
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="Address"
              class="w-full mt-3"
              :label="$t('Address')"
              icon-no-border
              :placeholder="$t('Address')+'*'"
              v-model="hospital.Address"
              :icon-after="true"
            />
          </div>
          <div div class="w-full justify-center">
            <label class="vs-input--label">{{$t('Country')}}*</label>
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="Country"
              class="w-full mt-2"
              :placeholder="$t('Country')"
              :icon-after="true"
              label="Name"
              :options="countries"
              :reduce="(ID) => ID.ID"
              v-model="hospital.CountryID"
              @input="getRegionByCountryId(hospital.CountryID)"
            />
          </div>

          <div div class="w-full justify-center">
              <label class="vs-input--label">{{$t('Provinance')}}*</label>
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="Region"
              class="w-full mt-2"
              :placeholder="$t('Provinance')"
              :icon-after="true"
              label="Name"
              :options="regions"
              :reduce="(ID) => ID.ID"
              v-model="hospital.RegionID"
              @input="getCityByRegionId(hospital.RegionID)"
            />
          </div>

          <div div class="w-full justify-center">
              <label class="vs-input--label">{{$t('City')}}*</label>
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="City"
              class="w-full mt-2"
              :placeholder="$t('City')"
              :icon-after="true"
              label="Name"
              :options="cities"
              :reduce="(ID) => ID.ID"
              v-model="hospital.CityID"
            />
          </div>
          <div class="mt-2 w-full justify-left">
            <h4>
              {{ $t("Accompanyingperson") }}
            </h4>
          </div>

          <vs-checkbox
            class="mt-1 w-full justify-left"
            v-model="hospital.IsAllowAccompanyingperson"
            >{{
              $t(
                "Forinpatientsanaccompanyingpersonisallowedtostaywiththepatientintheroom"
              )
            }}
          </vs-checkbox>

          <h4 class="justify-center mt-2">
            {{ $t("Facilities") }}
          </h4>

          <vs-row vs-lg="8" vs-sm="12" vs-justify="center" vs-type="flex">
            <vs-col
              v-for="item in facilities"
              :key="item.ID"
              class="justify-left mt-5"
              vs-sm="12"
              vs-lg="6"
              vs-type="flex"
            >
              <vs-row vs-w="12" vs-type="flex">
                <vs-col vs-sm="6" vs-lg="6">
                  <vs-checkbox v-model="item.IsChecked">{{ item.Name }}</vs-checkbox>
                </vs-col>

                <vs-col vs-sm="6" vs-lg="6">
                  <img
                    style="width: 50px; height: 20px"
                    alt=""
                    :src="baseURL + item.ImagePath"
                  />
                </vs-col>
              </vs-row>
            </vs-col>
          </vs-row>

          <div class="w-full text-end mt-5">
            <vs-button
            :disabled="!isFormValid"
              class="vx-col"
              type="filled"
              style="float: right"
              @click="UpdateHospital"
              >{{ $t("Submit") }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataViewSidebar from "../medicalWebinar/DataViewSidebar.vue";
import moduleCity from "@/store/settings/city/moduleCity.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleHospitalGroup from "@/store/settings/HospitalGroup/moduleHospitalGroup.js";
import moduleFacility from "@/store/settings/facility/moduleFacility.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import VuePhoneNumberInput from 'vue-phone-number-input';
export default {
  components: {
    DataViewSidebar,
    quillEditor,
    VuePhoneNumberInput

  },
  data() {
    return {
      phonevalue:'',
       editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'font': [] }],
          ],
        },
        placeholder: 'Message'
      },
      hospitalcountryID:0,
      EnglishLan: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      hospital: {
        HospitalAttachments: [],
        HospitalSpecialties: [],
        HospitalFacilities: [],
        ImagePath: "",
        hospital:{}
      },
      options: [],
      radios1: "luis",
       defaultPhoneData:{
        countryCode : ""
      }
    };
  },

  methods: {
  //   acceptNumber(value) {
  //     debugger;
  //  //   this.phonevalue = this.hospital.Phone ;
  // // var x = this.hospital.Phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  //        this.phonevalue = value.e164;
  //        this.hospital.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
  //   },

  acceptNumber(value) {
      debugger;
      if(this.hospital.CountryID !=null || this.hospitalcountryID !=0)
      {
      var country = this.countries.find((x) => (x.ID === this.hospital.CountryID ||x.ID=== this.hospitalcountryID));
      this.defaultPhoneData.countryCode =country.IsoCode;
      value.countryCode=this.defaultPhoneData.countryCode;
      this.phonevalue = value.e164;
      this.doctorModel.phone=this.phonevalue;
      }
      else
       {
         this.phonevalue = value.e164;
         this.defaultPhoneData.countryCode;
         }
       //  this.hospital.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
    },
    removeImage(item) {
      var index = this.hospital.HospitalAttachments.findIndex(
        (x) => x.AttachPath == item.AttachPath
      );
      this.hospital.HospitalAttachments.splice(index, 1);
      if (item.ID > 0) this.$store.dispatch("HospitalList/DeleteAttachmenByID", item.ID);
    },
    UpdateHospital() {
      debugger;
      this.hospital.Phone = this.phonevalue;
      this.hospital.HospitalFacilities = this.facilities
        .filter((obj) => obj.IsChecked)
        .map((b) => {
          var h = {};
          h.FacilityID = b.ID;
          return h;
        });

      this.$store
        .dispatch("HospitalList/UpdateHospital", this.hospital)
        .then((res) => {
          if (
            this.$store.state.AppActiveUser.Hospital != null &&
            this.$store.state.AppActiveUser.Hospital.ID > 0
          ) {
            window.showSuccess();
            this.$router.go(-1);
          } else {
            this.$vs.notify({
              color: "success",
              title: "Successfully",
              text:
                "please wait till you confirm your account, you  will notify by mail",
            });
            this.$router.push("/pages/login");
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    getRegionByCountryId(CountryId) {
      this.$store.dispatch("RegionList/GetAllRegionsByCountryID", CountryId).then(() => {
        if (this.hospital.RegionID > 0) {
          this.getCityByRegionId(this.hospital.RegionID);
        }
      });
    },
    getCityByRegionId(RegionId) {
      this.$store.dispatch("CityList/GetAllCitiesByRegionID", RegionId);
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          this.hospital.ImagePath = response.data;
        });
      }
    },
    AttachmentUploaded(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          var temp = {};
          temp.AttachPath = response.data;
          this.hospital.HospitalAttachments.push(temp);
        });
      }
    },
    uploadHospitalAttachment(event) {
      debugger;
      var temp = {};
      temp.AttachPath = event.target.responseText;
      this.hospital.HospitalAttachments.push(temp);
    },
  },
  computed: {
    isFormValid()
    {
          return !this.errors.any() &&  this.hospital.NameEN
           && this.hospital.HospitalInfoEN
           &&  this.hospital.Address
          &&  this.hospital.ContactPersonEmail
          &&  this.hospital.CountryID
          &&  this.hospital.CityID
           &&  this.hospital.RegionID
    },
    cities() {
      return this.$store.state.CityList.cities;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    regions() {
      debugger;
      return this.$store.state.RegionList.regions;
    },
    hospitalGroups() {
      return this.$store.state.HospitalGroupList.HospitalGroups;
    },
    facilities() {
      debugger;
      return this.$store.state.FacilityList.facilities;
    },
    filterHospitalAttachments() {
      return this.hospital.HospitalAttachments.filter((obj) => obj.ID > 0);
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleHospitalGroup.isRegistered) {
      this.$store.registerModule("HospitalGroupList", moduleHospitalGroup);
      moduleHospitalGroup.isRegistered = true;
    }
    if (!moduleFacility.isRegistered) {
      this.$store.registerModule("FacilityList", moduleFacility);
      moduleFacility.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.$store.dispatch("FacilityList/GetAllFacilities");
    this.$store.dispatch("HospitalGroupList/GetAllHospitalGroups");
    this.$store.dispatch("CountryList/GetAllCountries");

    var id = this.$route.params.Id;

    if (id) {
      this.$store.dispatch("HospitalList/GetHospital", id).then((res) => {
        debugger;
        this.hospital = res.data.Data;
        this.phonevalue=res.data.Data.Phone;
        this.hospital.Phone=res.data.Data.Phone;
        this.hospitalcountryID=res.data.Data.CountryID;

        if (this.hospital.CountryID > 0) {
          this.getRegionByCountryId(this.hospital.CountryID);
        }
        if (this.hospital.HospitalFacilities != null) {
          this.hospital.HospitalFacilities.forEach((element) => {
            var index = this.facilities.findIndex((x) => x.ID == element.FacilityID);
            debugger;
            if (index >= 0) this.facilities[index].IsChecked = true;
          });
        }
      });
    }
  },
};
</script>
<style></style>
